import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SetpasswordService } from './setpassword.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {
  setpasswordForm: FormGroup;
  submitted = false;
  showModal: boolean;
  responseMessage: any;
  errorMessage: any;
  userType: any;
  navigator: any;
  buttonText = "Submit";
  token: any;
  invalidConfirmPswd: any;

  constructor(private router: Router, private formBuilder: FormBuilder, private apicall: SetpasswordService, private route: ActivatedRoute) {
    this.route.params.subscribe(data =>
      this.token = data.token
    );
  }

  ngOnInit() {
    this.setpasswordForm = this.formBuilder.group({
      password: ["", [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{5,}')]],
      cpassword: [""]
    });
    this.userType = localStorage.getItem('userType');
    if (this.userType == 'admins') {
      this.navigator = '/adminlogin'
    }
    else {
      this.navigator = '/login'
    }
  }
  // convenience getter for easy access to form fields
  get f() { return this.setpasswordForm.controls; }


  onSubmit() {
    this.submitted = true;
    this.errorMessage = "";
    this.invalidConfirmPswd = false;
    // stop here if form is invalid
    if (this.setpasswordForm.invalid) {
      return;
    } else {
      this.buttonText = "Loading...";
      if (this.setpasswordForm.value.password == this.setpasswordForm.value.cpassword) {
        const emailID = localStorage.getItem('emailAddress');
        const obj = {
          email_id: emailID,
          password: this.setpasswordForm.value.password,
          token: this.token
        }
        this.apicall.setpassword(obj, this.userType).subscribe(response => {
          this.responseMessage = response;
          localStorage.setItem('userData', JSON.stringify(this.responseMessage.data.userData));
          localStorage.setItem('token', this.responseMessage.data.token);
          if (this.responseMessage.success == true) {
            //console.log('/user/appointments/book')
            window.location.href = '/user/appointments/book';
          } else {
            this.errorMessage = this.responseMessage.message;
          }
        },
          error => {
            this.errorMessage = error.error.message;
            this.buttonText = "Submit";
          });
      } else {
        this.invalidConfirmPswd = true;
        this.errorMessage = 'Password and confirm password did not matched.';
        this.buttonText = "Submit";
      }
    }
  }

  removeMessage() {
    if(this.errorMessage) {
      this.errorMessage = "";
    }
  }
}
