import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ResetpasswordService } from './reset-password.service';
import { ActivatedRoute } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-rest-password',
  templateUrl: './rest-password.component.html',
  styleUrls: ['./rest-password.component.css']
})
export class RestPasswordComponent implements OnInit {

  resetpasswordForm: FormGroup;
  submitted = false;
  showModal: boolean;
  responseMessage: any;
  errorMessage: any;
  routId: any;
  obj: any;
  userType: string;
  navigator: string;
  buttonText = "Submit";

  constructor(private formBuilder: FormBuilder, private apicall: ResetpasswordService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(data =>
      this.routId = data
    );
    this.resetpasswordForm = this.formBuilder.group({
      password: ["", [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{5,}')]],
      cpassword: ["", [Validators.required, Validators.minLength(6)]]
    });

    this.userType = localStorage.getItem('userType');
    if (this.userType == 'admins') {
      this.navigator = '/adminlogin'
    } else {
      this.navigator = '/login'
    }
  }
  // convenience getter for easy access to form fields
  get f() { return this.resetpasswordForm.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.resetpasswordForm.invalid) {
      return;
    } else {
      this.buttonText = "Loading...";
      if (this.resetpasswordForm.value.password == this.resetpasswordForm.value.cpassword) {
        if (this.userType == 'admins') {
          this.obj = {
            admin_id: this.routId.Id,
            password: this.resetpasswordForm.value.password,
          }
        } else {
          this.obj = {
            user_id: this.routId.Id,
            password: this.resetpasswordForm.value.password,
          }
        }

        this.apicall.resetpassword(this.obj, this.userType).subscribe(response => {
          this.responseMessage = response;
          if (this.responseMessage.success == true) {
            $('#success-popup').modal({ backdrop: 'static', keyboard: false })
            $('#success-popup').modal('show');
            this.buttonText = "Submit";
          } else {
            this.errorMessage = this.responseMessage.message;
          }
        }, error => {
            this.errorMessage = error.error.message;
            this.buttonText = "Submit";
          });
      } else {
        this.errorMessage = 'Password and confirm password did not matched.';
        this.buttonText = "Submit";
      }
    }
  }
}
