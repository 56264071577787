import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginadminService } from './loginadmin.service';
declare var $: any;
import { patternValidator } from 'src/app/patternValidator';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class AdminLoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  showModal: boolean;
  responseMessage: any;
  errorMessage: any;
  buttonText = "Login";

  constructor(private formBuilder: FormBuilder, private apicall: LoginadminService) {
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    localStorage.removeItem('userData');
  }

  ngOnInit() {
    localStorage.setItem('userType', 'admins');
    this.loginForm = this.formBuilder.group({
      username: ["", [Validators.required, patternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      password: ["", [Validators.required, Validators.minLength(2)]],
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    } else {
      this.buttonText = "Loading..."
      this.apicall.adminLogin(this.loginForm.value).subscribe(response => {
        this.responseMessage = response;
        if (this.responseMessage.success == true) {
          localStorage.setItem('token', this.responseMessage.data.token);
          $('#success-popup').modal({ backdrop: 'static', keyboard: false })
          $('#success-popup').modal('show');
          this.buttonText = "Login";
        } else {
          this.errorMessage = this.responseMessage.message;
        }
      },
        error => {
          this.errorMessage = error.error.message;
          this.buttonText = "Login";
        });
    }
  }
}
